<template>
  <div class="shoppingCart">
    <!-- 购物车头部 -->
    <div class="cart-header">
      <p>
        {{ $t('cart.cart') }}
      </p>
    </div>
    <!-- 购物车头部END -->
    <!-- 购物车主要内容区 -->
    <div class="content" v-if="getShoppingCart.length > 0">
      <div class="list">
        <table>
          <thead>
            <tr style="height: 45px;">
              <th>
                <el-checkbox v-model="isAllCheck" style="margin-left: 30px;"></el-checkbox>
              </th>
              <th>{{ $t('table.productname') }}</th>
              <th>name</th>
              <!-- <th>{{ $t('table.stockquantity') }}</th> -->
              <th>{{ $t('table.price') }}</th>
              <!-- <th>{{ $t('table.packingquantity') }}</th> -->
              <!-- <th>{{ $t('table.consolidate') }}</th> -->
              <th>{{ $t('table.numberorders') }}</th>
              <th>{{ $t('table.total') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getShoppingCart" :key="item.id"
              :class="{ 'no-border': index === getShoppingCart.length - 1 }">
              <td>
                <el-checkbox :value="item.check" @change="checkChange($event, index)"
                  style="margin-left: 30px;"></el-checkbox>
              </td>
              <td>
                <div>
                  <router-link :to="{ path: '/personalcenter/Cart/details', query: { spuId: item.spuId } }">
                    <img style=" height: 120px; width: 120px;" v-lazy="item.imageUrl" />
                  </router-link>
                </div>
              </td>
              <td>{{ item.skuName }}</td>
              <!-- <td>{{ item.stockNum }}</td> -->
              <td>${{ item.price }}</td>
              <!-- <td>{{ item.amount }}</td> -->
              <!-- <td>{{ item.isLcl === 0 ? $t('table.no') : $t('table.yes') }}</td> -->
              <td>
                <el-input-number v-if="item.isLcl === 0" style="width: 120px;" :step="item.amount" size="mini"
                  :value="item.num" @change="handleChange($event, index, item.id)" :min="1"
                  step-strictly></el-input-number>
                <el-input-number v-else style="width: 120px;" size="mini" :value="item.num"
                  @change="handleChange($event, index, item.id)" :min="1"></el-input-number>
              </td>
              <td>${{ formatF((item.price * 1000) * item.num / 1000) }}</td>
              <td>
                <img src="../../assets/imgs/uploads/Vector1.png" alt="" @click="deleteData(item.id)"
                  style="width: 15px;height: 15px;margin-right: 38px;cursor: pointer;">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 购物车底部导航条 -->
      <div class="cart-bar">
        <div>
          <span>{{ $t('cart.total') }} <span class="cart-total-num">{{ getNum }}</span> {{
            $t('table.pieces') }}，{{ $t('cart.chosen') }}
            <span class="cart-total-num">{{ getCheckNum }}</span> {{ $t('table.pieces') }}
          </span>
        </div>
        <div class="total-price-title">
          Grand Total: $ {{ getTotalPrice }}
        </div>
      </div>
      <button class="btn" @click="send" :class="getCheckNum > 0 ? 'btn-primary' : 'btn-primary-disabled'">Place
        Order</button>
      <!-- 购物车底部导航条END -->
    </div>
    <!-- 购物车主要内容区END -->
    <!-- 购物车为空的时候显示的内容 -->
    <div v-else class="cart-empty">
      <div class="empty">
        <h2>Your cart is still empty！</h2>
        <p>Go shopping！</p>
      </div>
    </div>
    <!-- 购物车为空的时候显示的内容END -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      value: '',
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(["updateShoppingCart", "deleteShoppingCart", "checkAll"]),
    // 修改商品数量的时候调用该函数
    handleChange(currentValue, key, id) {
      // 当修改数量时，默认勾选
      this.updateShoppingCart({ key: key, prop: "check", val: true });
      // 向后端发起更新购物车的数据库信息请求
      this.$axios
        .post("/api/mall/cart/updateCart", {
          id: id,
          num: currentValue
        })
        .then(res => {
          switch (res.data.code) {
            case 200:
              // 更新vuex状态
              this.updateShoppingCart({
                key: key,
                prop: "num",
                val: currentValue
              });
              break;
            default:
              this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    send() {
      this.$router.push({
        path: '/placeorder',
      })
    },
    deleteData(id) {
      var that = this
      this.$confirm(this.$t('cart.del'), this.$t('cart.tip'), {
        confirmButtonText: this.$t('cart.confirm'),
        cancelButtonText: this.$t('cart.think'),
        type: 'warning',
        center: true
      }).then(() => {
        that.deleteItem(id)
      }).catch(() => {
      });
    },
    formatF(value) {
      let value1 = Math.round(parseFloat(value) * 100) / 100;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value1 = value.toString() + ".00";
        return value1;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value1 = value.toString() + "0";
        }
        return value1;
      }
    },
    checkChange(val, key) {
      // 更新vuex中购物车商品是否勾选的状态
      this.updateShoppingCart({ key: key, prop: "check", val: val });
    },
    // 向后端发起删除购物车的数据库信息请求
    deleteItem(id) {
      this.$axios
        .post("/api/mall/cart/deleteCart", {
          memberId: this.$store.getters.getUser.id,
          id: id
        })
        .then(res => {
          switch (res.data.code) {
            case 200:
              // “001” 删除成功
              // 更新vuex状态
              this.deleteShoppingCart(id);
              // 提示删除成功信息
              this.notifySucceed(this.$t('cart.deleted'));
              break;
            default:
              // 提示删除失败信息
              this.notifyError(res.data.message);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
  },
  computed: {
    ...mapGetters([
      "getShoppingCart",
      "getCheckNum",
      "getTotalPrice",
      "getNum"
    ]),
    isAllCheck: {
      get() {
        return this.$store.getters.getIsAllCheck;
      },
      set(val) {
        this.checkAll(val);
      }
    }
  }
};
</script>
<style scoped>
.cart-header {
  font-weight: bold;
  font-size: 20px;
  color: #333;
}

.list {
  margin-top: 33px;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
}

/* 购物车主要内容区CSS */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  /* 将表格内容水平居中 */
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333;
  border-color: #959595;
}

::v-deep .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #959595;
}

::v-deep .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
  border-color: #959595;
}

/* ::v-deep .el-input-number__decrease,
.el-input-number__increase {
  background: #000;
  color: #FFF;
} */

/* ::v-deep .el-input-number__decrease.is-disabled,
.el-input-number__increase.is-disabled {
  color: #FFF;
} */

/* ::v-deep .el-input-number__increase {
  background: #000;
  color: #FFF;
}

::v-deep .el-input__inner {
  border: 1px solid #000;
} */

th,
td {
  padding: 8px 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

td {
  height: 146px;
}

.cart-bar {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #D9D9D9;
}

.cart-total-num {
  font-weight: bold;
  color: #000;
}

.total-price-title {
  font-size: 18px;
  font-weight: bold;
}

.btn {
  width: 128px;
  height: 36px;
  margin-top: 13px;
  float: right;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
}

.btn-primary-disabled {
  pointer-events: none;
}

.no-border td {
  border-bottom: none !important;
}

/* 购物车为空的时候显示的内容CSS */
.shoppingCart .cart-empty {
  width: 1225px;
  margin: 0 auto;
}

.shoppingCart .cart-empty .empty {
  height: 300px;
  padding: 0 auto;
  margin: 65px 0 0;
  color: #b0b0b0;
  overflow: hidden;
}

.shoppingCart .cart-empty .empty h2 {
  margin: 70px 0 15px;
  font-size: 36px;
}

.shoppingCart .cart-empty .empty p {
  margin: 0 0 20px;
  font-size: 20px;
}

.kuanshi {
  color: #855d5d;
  font-size: 12px;
  margin-left: 10px;
}

/* 购物车为空的时候显示的内容CSS END */</style>